import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
const Doc = ()=>{
  return(
    <React.StrictMode>
    <App />  
    </React.StrictMode>

  )
}


ReactDOM.render(Doc(),
  document.getElementById('root')
);

export default  Doc;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log('err : ' + err));
