const runrand =(len, min, max)=>{
    return Math.round(Math.round(Math.random() * 10) * 1000)
}
export const products = [
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'},
    {name:"name", img : 'img',price:runrand(), desc : 'desc'}
]

export const cate = [
    {sex : "Men", link : ""},
    {sex : "Women", link : ""},
    {sex : "Kids", link : ""},
    {sex : "Alte", link : ""},
    {sex : "About Us", link : ""},
]
